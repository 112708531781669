import { chakra, CardBody, Flex, Heading, Text, useColorModeValue, Avatar, Link, Box, useToast, Button, ModalFooter, ModalBody, ModalCloseButton, ModalHeader, ModalContent, ModalOverlay, Modal, useDisclosure } from "@chakra-ui/react";
import { HashLink } from "react-router-hash-link";

export function SlidersContent({serviceName='any', descricao=[]}:any) {
	const backgrounds = [
		`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%2373C990' /%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%23FFFFFF' /%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%2373C990' /%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23FFFFFF' /%3E%3C/svg%3E")`
	  ];
	const toast = useToast()
	  
	const { isOpen, onOpen, onClose } = useDisclosure()
	  
	return (
	  	<>
			<Box height={["12rem","10rem"]} width="100%">
				<Flex
					w="100%"
					height="100%"
					boxShadow={'lg'}
					maxW={'640px'}
					direction={{ base: 'column-reverse', md: 'row' }}
					width={'full'}
					rounded={'xl'}
					p={10}
					justifyContent={'space-between'}
					position={'relative'}
					bg={useColorModeValue('white', 'gray.800')}
					_before={{
						content: '""',
						position: 'absolute',
						zIndex: '-1',
						height: 'full',
						maxW: '640px',
						width: 'full',
						filter: 'blur(40px)',
						transform: 'scale(0.98)',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						top: 0,
						left: 0,
						backgroundImage: backgrounds[0],
					}}
				
				>
					<Flex
						direction={'column'}
						textAlign={'left'}
						justifyContent={'space-between'}
					>
						<Heading
							fontWeight={'medium'}
							pb={1}
							bgGradient={useColorModeValue("linear(0deg, gray.900, gray.900)","linear(0deg, gray.200, gray.500)")}
							bgClip="text"
							fontSize="2rem"
						>
							{ serviceName }
						</Heading>

						<chakra.span
							fontFamily={'Inter'}
							fontWeight={'medium'}
							color={'gray.500'}
						>
							{' '}
							<Link display="block" onClick={onOpen}> Saiba mais </Link>
							{/* <Link display="block" href={`/services/${serviceName}`}> Saiba mais </Link> */}
						</chakra.span>

					</Flex>
				</Flex>
			</Box>


			<Modal isOpen={isOpen} size={"4xl"} onClose={onClose}>

					<ModalOverlay />
					<ModalContent >
						<ModalHeader>
							<Heading marginTop="2rem">
								{serviceName}
							</Heading>
						</ModalHeader>
						
						<ModalCloseButton />
						
						<ModalBody>
							{
								descricao.map((descricaoText:any)=>{
									return(
										<Text marginBottom="1rem">
											{descricaoText}
										</Text>
									);
								})
							}
						</ModalBody>

						<ModalFooter>
							<Button mr={3} onClick={onClose} variant='ghost'>
								Fechar
							</Button>
						
							<Button as={HashLink} colorScheme='blue' to="#contact" onClick={()=>{
								onClose()
								toast({
									title: `Entre em contato através do formulário!`,
									description: 'Obrigado.',
									variant: 'left-accent',
									isClosable: true,
									duration: 9000,
									status: 'info'
								})}} >Entrar em Contato</Button>
						</ModalFooter>
					</ModalContent>
			</Modal>
		</>
	);
  }
  