import { ReactNode } from 'react';
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  Flex,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { HashLink } from 'react-router-hash-link';

function PriceWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}>
      {children}
    </Box>
  );
}

export function ThreeTierPricing() {
	const toast = useToast()

	const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box py={12} as="section">
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          Melhores planos para você
        </Heading>
        <Text fontSize="lg" color={'gray.500'}>
          Comece hoje mesmo
        </Text>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}>

		<PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" overflowWrap="break-word" fontSize="2xl">
				Manutenção de Site
            </Text>
			<Text fontSize="lg" marginTop="0.5rem" color={'gray.500'}>
				A partir de
			</Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                R$
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                80,00
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /mês
              </Text>
            </HStack>
          </Box>
		  {/* 
					a) Atualização do Certificado SSL; 
					b) Correção de Bugs ou Falhas no código do site; 
					c) Mudanças no Layout e cores do site; 
					d) Mudanças em URL’s do site;
					e) Atualização no Blog conforme necessidade;
					f) Atualização de texto e imagem.

				*/}
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius={'xl'}>
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Atualização do Certificado SSL.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Correção de Bugs ou Falhas no código do site.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Mudanças simples no Layout e cores do site.
              </ListItem>
			  <ListItem>
				<ListIcon as={FaCheckCircle} color="green.500" />
				Atualização de texto e imagem.
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
			  <Button as={HashLink} w="full" colorScheme='red' variant="outline" to="#contact" onClick={()=>{
				onClose()
				toast({
					title: `Entre em contato através do formulário.`,
					variant: 'left-accent',
					isClosable: true,
					duration: 9000,
					status: 'info'
				})}}>
					Saiba mais
			  </Button>
            </Box>
          </VStack>
        </PriceWrapper>

	    <PriceWrapper>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}>
              <Text
                textTransform="uppercase"
                bg={useColorModeValue('red.300', 'red.700')}
                px={3}
                py={1}
                color={useColorModeValue('gray.900', 'gray.300')}
                fontSize="sm"
                fontWeight="600"
                rounded="xl">
                Mais Popular
              </Text>
            </Box>
            <Box py={4} px={12}>
				<Text fontWeight="500" fontSize="2xl">
					Desenvolvimento de Website
				</Text>
				<Text fontSize="lg" marginTop="0.5rem" color={'gray.500'}>
					A partir de
				</Text>
				<HStack justifyContent="center">
					<Text fontSize="3xl" fontWeight="600">
						R$
					</Text>
					<Text fontSize="5xl" fontWeight="900">
						750,00
					</Text>
				</HStack>			
            </Box>
            <VStack
              bg={useColorModeValue('gray.50', 'gray.700')}
              py={4}
              borderBottomRadius={'xl'}>
              <List spacing={3} textAlign="start" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Site Responsivo.
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Suporte ao Cliente.
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Boa classificação em motores de busca.
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Modo escuro/claro.
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Design Moderno.
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
			  <Button as={HashLink} w="full" colorScheme='red' to="#contact" onClick={()=>{
				onClose()
				toast({
					title: `Entre em contato através do formulário.`,
					variant: 'left-accent',
					isClosable: true,
					duration: 9000,
					status: 'info'
				})}}>
					Saiba mais
			  </Button>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>

      </Stack>
    </Box>
  );
}