import { ReactElement } from 'react';
import { Box, SimpleGrid, Icon, Text, Stack, Flex, Container } from '@chakra-ui/react';
import { FcAssistant, FcDonate, FcFlashOn, FcInTransit } from 'react-icons/fc';

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack data-aos="fade-up" border="solid 0.06rem #1a202c" boxShadow="0rem 1rem 2rem #0000006b" borderRadius="1rem" padding="1rem">
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        bg={'gray.100'}
        mb={1}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={'gray.600'}>{text}</Text>
    </Stack>
  );
};

export function Services() {
  return (
	<Container as="section" maxW={"9xl"}>

		<Box p={4} pt="7rem" pb="4rem"
		id="services">
		<SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
			<Feature	
				icon={<Icon as={FcAssistant} w={10} h={10} />}
				title={'Atendimento Rápido'}
				text={
					'Nossos profissionais estarão disponíveis para sanar suas dúvidas o mais rápido possível, basta solicitar.'
				}
			/>
			<Feature
				icon={<Icon as={FcDonate} w={10} h={10} />}
				title={'Economize seu Dinheiro!'}
				text={
					'Somos justos na questão de pagamentos por serviço, a prioridade é o cliente estar satisfeito.'
				}
			/>
			<Feature
				icon={<Icon as={FcFlashOn} w={10} h={10} />}
				title={'Retorno Rápido'}
				text={
					'Nós garantimos que seu projeto seja entregue o mais rápido, dentro do prazo e com a máxima eficiência.'
				}
			/>
		</SimpleGrid>
		</Box>
	</Container>
  );
}