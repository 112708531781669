import { ColorModeScript } from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { BrowserRouter } from 'react-router-dom'
import { App } from "./App"
import Aos from "aos"

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

		Aos.init({
			startEvent: 'load',
		
			// Global settings:
			duration: 500, // values from 0 to 3000, with step 50ms
			easing: 'ease', // default easing for AOS animations
			anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
		})
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ColorModeScript />
			<App />
		</BrowserRouter>
	</React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals