import { Swiper, SwiperProps, SwiperSlide } from "swiper/react"
import { A11y, Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Mousewheel } from "swiper"

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { SlidersContent } from "../SlidersContent";
import { Box } from "@chakra-ui/react"
import { useRef } from "react"

export function Sliders(){

	// Criação de websites institucionais;
	// Desenvolvimento de lojas virtuais;
	// Desenvolvimento de aplicativos web;
	// Integração de sistemas e APIs;
	// Desenvolvimento de sistemas customizados;
	// Otimização de sites para mecanismos de busca (SEO);
	// Desenvolvimento de landing pages;
	// Migração de plataformas;
	// Manutenção e suporte técnico para sites e sistemas;
	// Implementação de chatbots e assistentes virtuais.


	const servicesArray=[
		{
			serviceName: 'Criação de websites institucionais',
			descricao: [
				"A criação de websites institucionais é um serviço que consiste em desenvolver websites para empresas e organizações que desejam ter uma presença online profissional e impactante. Esses websites têm como objetivo apresentar informações institucionais da empresa, como sua história, valores, produtos ou serviços oferecidos, equipe e contatos. A criação de websites institucionais deve levar em conta a identidade visual da empresa, a usabilidade do site e a experiência do usuário.",

				"No processo de criação de websites institucionais, é importante contar com uma equipe especializada em design gráfico e desenvolvimento web, que possua habilidades técnicas e criativas para criar websites que atendam às necessidades e expectativas da empresa. Essa equipe deve ser capaz de criar um website responsivo, que se adapte a diferentes dispositivos e telas, e que possua uma arquitetura de informação clara e intuitiva, para facilitar a navegação do usuário.",

				"Por fim, é fundamental que a criação de websites institucionais seja feita com base em boas práticas de otimização para motores de busca (SEO), de forma a garantir que o site seja encontrado pelos usuários que buscam pelos serviços ou produtos da empresa. Isso pode incluir a definição de palavras-chave relevantes, otimização de conteúdo, criação de URLs amigáveis e outras técnicas de SEO. Com a ajuda de um serviço de criação de websites institucionais de qualidade, empresas podem aumentar sua visibilidade na internet, fortalecer sua marca e atrair mais clientes."
			]
		},

		{
			serviceName: 'Desenvolvimento de APIs',
			descricao: [
				"O desenvolvimento de APIs é um serviço que consiste em criar interfaces de programação de aplicativos (APIs) que permitem que diferentes softwares possam se comunicar entre si de forma eficiente e segura. As APIs são conjuntos de rotinas, protocolos e ferramentas para construir softwares e integrá-los a outros sistemas, permitindo a troca de informações e dados de maneira automatizada. Esse serviço é fundamental para empresas que desejam integrar seus sistemas internos ou disponibilizar seus serviços para outras empresas ou desenvolvedores externos.",

				"No processo de desenvolvimento de APIs, é necessário levar em conta diversos aspectos, como a segurança, a escalabilidade e a eficiência das comunicações entre os sistemas. Além disso, é importante pensar na documentação da API, para que outras empresas ou desenvolvedores possam entender como utilizá-la e quais são suas funcionalidades. Para isso, é comum utilizar ferramentas de desenvolvimento de APIs, como Swagger, que permitem a documentação e teste de APIs de maneira eficiente.",

				"Por fim, o serviço de desenvolvimento de APIs requer uma equipe de desenvolvedores experientes e capacitados, que conheçam bem as tecnologias envolvidas e as melhores práticas de desenvolvimento de software. Essa equipe deve estar preparada para lidar com desafios complexos, como integração de sistemas legados, escalabilidade de APIs em casos de alto tráfego, e segurança de dados sensíveis que são trocados entre os sistemas. Com a ajuda de um serviço de desenvolvimento de APIs de qualidade, empresas podem melhorar sua eficiência, expandir seus negócios e fornecer serviços de alta qualidade para seus clientes."
			]
		},

		{
			serviceName: 'Desenvolvimento de formulários web',
			descricao: [
				"O serviço de desenvolvimento de formulários web consiste na criação de formulários online personalizados para atender às necessidades de cada cliente. Esses formulários podem ser usados para diversas finalidades, como pesquisa de opinião, inscrição em eventos, coleta de dados de contato, entre outros.",

				"Os formulários web desenvolvidos por uma empresa especializada têm como objetivo fornecer uma experiência de usuário intuitiva e eficiente, com design atrativo e funcionalidades que facilitam a coleta de informações. Além disso, esses formulários são programados para garantir a segurança das informações coletadas, com criptografia de dados e medidas de proteção contra invasões.",
				
				"Ao contratar uma empresa especializada em desenvolvimento de formulários web, o cliente tem a garantia de que receberá um formulário personalizado, de acordo com suas necessidades e objetivos. Isso pode trazer diversas vantagens para o negócio, como a possibilidade de coletar informações valiosas sobre o público-alvo e melhorar a comunicação com os clientes, além de otimizar processos internos e aumentar a eficiência do negócio."
			]
		},

		{
			serviceName: 'Otimização de sites para mecanismos de busca (SEO)',
			descricao: [
				"A otimização de sites para mecanismos de busca (SEO) é um conjunto de técnicas que têm como objetivo melhorar a visibilidade e o posicionamento de um site nos resultados de busca orgânica dos mecanismos de pesquisa, como o Google. O SEO envolve uma série de atividades, como a seleção de palavras-chave relevantes para o negócio, a produção de conteúdo de qualidade, a otimização técnica do site, entre outras.",

				"Contratar uma empresa especializada em otimização de sites para mecanismos de busca pode trazer diversas vantagens para o negócio. Primeiramente, uma empresa especializada tem o conhecimento e a experiência necessários para identificar as palavras-chave mais relevantes para o negócio e otimizar o site para essas palavras-chave, aumentando assim a visibilidade do site nos resultados de busca.",

				"Além disso, uma empresa de SEO pode ajudar a melhorar a experiência do usuário no site, tornando-o mais fácil de navegar e encontrando informações relevantes de forma rápida e simples. Isso não só melhora a experiência do usuário, como também ajuda a aumentar a taxa de conversão do site. Por fim, o SEO é uma estratégia de longo prazo, e a contratação de uma empresa especializada pode trazer resultados duradouros e consistentes para o negócio ao longo do tempo."
			]
		},

		{
			serviceName: 'Desenvolvimento de landing pages',
			descricao: [
				"O serviço de desenvolvimento de landing pages é uma estratégia digital que visa criar uma página otimizada para conversão, ou seja, com o objetivo de levar o visitante a realizar uma ação específica, como preencher um formulário ou realizar uma compra.",

				"A landing page é uma página com um design clean, simples e focado, que busca despertar a atenção do visitante e convencê-lo a realizar uma ação específica. Para isso, a página deve ter um conteúdo claro e persuasivo, além de elementos visuais que ajudem a destacar a mensagem principal.",

				"O serviço de desenvolvimento de landing pages é importante para empresas que desejam maximizar o potencial de suas campanhas de marketing digital, uma vez que uma página otimizada para conversão pode aumentar significativamente a taxa de conversão e gerar mais leads e vendas."
			]
		},

		{
			serviceName: 'Criação de blogs e portais de notícias',
			descricao: [
				"Nosso serviço de criação de blogs e portais de notícias é a solução perfeita para empresas que desejam ter uma presença online forte e impactante. Oferecemos desde o planejamento e design até a implantação e manutenção do site, garantindo uma experiência de usuário agradável e um produto final de alta qualidade.",

				"Utilizamos as mais modernas ferramentas e tecnologias para desenvolvimento de sites, com foco na usabilidade e na performance. Nossa equipe de designers e desenvolvedores experientes está sempre atualizada e capacitada para criar sites personalizados e exclusivos, que atendam às necessidades específicas de cada cliente.",
				
				"Por fim, oferecemos um serviço de suporte e manutenção contínuo, garantindo que o site esteja sempre atualizado e funcionando corretamente. Com a ajuda de nosso serviço de criação de blogs e portais de notícias, empresas podem aumentar sua visibilidade na internet, fortalecer a sua marca e estabelecer uma relação de confiança com o seu público-alvo."
			]
		},

		{
			serviceName: 'Desenvolvimento de aplicativos móveis (iOS e Android)',
			descricao: [
				"Nosso serviço de desenvolvimento de aplicativos móveis (iOS e Android) é uma solução completa e personalizada para empresas que desejam ter presença em dispositivos móveis. Oferecemos desde o planejamento e desenvolvimento até a implantação e manutenção do aplicativo, garantindo uma experiência de usuário satisfatória e um produto final de alta qualidade.",

				"Utilizamos as mais modernas ferramentas e tecnologias para desenvolvimento de aplicativos, com foco na usabilidade e na performance. Nossa equipe de desenvolvedores experientes está sempre atualizada e capacitada para criar aplicativos personalizados e exclusivos, que atendam às necessidades específicas de cada cliente.",

				"Por fim, oferecemos um serviço de suporte e manutenção contínuo, garantindo que o aplicativo esteja sempre atualizado e funcionando corretamente. Com a ajuda de nosso serviço de desenvolvimento de aplicativos móveis, empresas podem expandir sua presença digital, aumentar a visibilidade de sua marca e oferecer aos clientes uma experiência única e diferenciada."
			]
		},

	]

	return(
		
		<Swiper
			id="swiperHome"
			direction={"vertical"}
			pagination={{
				clickable: true,
			}}
			slidesPerView={2}
			spaceBetween={100}
			modules={[Pagination,Autoplay, Mousewheel]}
			className="mySwiper"
			centeredSlides={true}
			mousewheel={true}
			loop={true}
			autoplay={{
				delay: 2500,
				disableOnInteraction: false,
			  }}

			style={{
				height:"100%",
				width: "100%",
			}}
		>
			{
				servicesArray.map((services:any)=>{
					return(
						<SwiperSlide>
							<SlidersContent serviceName={services.serviceName} descricao={services.descricao} />
						</SwiperSlide>
					);
				})
			}
			
		
		</Swiper>
	);
}