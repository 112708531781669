import { Box, Button, Link } from "@chakra-ui/react";
import './styles.scss'
export function NotFound() {
  return (
	<Box className="errorNotFound">
		<Box className="container">
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			<span className="particle">0</span>    <span className="particle">4</span>
			
			<Box as="article" className="content">
				<p>Olá Usuário!</p>
				<p>Você se encontra perdido na galáxia do <strong>error 404</strong></p>
				<p>
				<Button as={Link} href="/"
					display="inline-block"
					marginTop="2rem"
					padding="0.5rem 1rem"
					border="3px solid #393939"
					background="transparent"
					fontSize="1rem"
					color="#393939"
					textDecoration="none"
					cursor="pointer"
					fontWeight="bold"
				>Voltar para Casa?</Button>
				</p>
			</Box>
		</Box>
	</Box>

  );
}
