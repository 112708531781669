import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Header } from "./components/Header/index"
import { Initial } from "./components/Inital"
import { Route, Routes } from "react-router-dom"
import { NotFound } from "./components/NotFound/NotFound"
import { Contact } from "./components/Contact"

import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
	colors: {
	  logo: {
		100: "#ffffff",
		// ...
		300: "#03fa6e",
		
		900: "#050a30",
	  },
	},
  })

  
export const App = () => (
	<ChakraProvider theme={theme}>

		<Header />

		<main id="main">
				<Routes>
					<Route path="/" element={<Initial />}/>
					<Route path="/contact/" element={<Contact />}/>
					<Route path="*" element={<NotFound />}/>
				</Routes>

		</main>
	</ChakraProvider>
)
