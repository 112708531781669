import { Contact } from './Contact';
import { Footer } from './Footer';
import { Header } from './Header/';
import { Hero } from './Hero';
import { ThreeTierPricing } from './Pricing';
import { Services } from './Services';
import { SitesDeveloped } from './SitesDeveloped';
import { Team } from './Team';

export function Initial(){
	return(
		<>		
			<Hero />
			<SitesDeveloped/>
			<Services />
			<ThreeTierPricing/>
			<Team />
			<Contact />
			<Footer />
		</>
	)
}