import { Box, Container, Flex, Heading, Image, Text, useColorModeValue } from "@chakra-ui/react";
import './styles.scss'

export function Team(){
	return(
		<Box as="section" bgColor={useColorModeValue('logo.900', '#2d3748')}>

			<Box className="backgroundFoundTeam" overflow="hidden" position="relative">
				<Container maxW={"7xl"} >
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>
					<span className="particle">{'<>'}</span>    <span className="particle">{'</>'}</span>

					<Flex alignItems="center"  flexDirection={["column", "column","row"]} padding="2rem" minH="100%" justifyContent="space-between" w="100%">
						<Box w={["100%", "100%", "40%"]} maxH="100%" data-aos="fade-right">
								<Image w="19.25rem" src="./img/Gustavo.png" title="Gustavo Ramos" alt="Gustavo Ramos"/>
						</Box>
						
						<Box w="100%" alignSelf="flex-start" minHeight="100%" maxH="100%" margin="1rem">
							<Heading marginBottom="2rem" color="logo.300" data-aos="fade-up">MELHORES PROFISSIONAIS</Heading>
							<Text color="gray.50" data-aos="fade-left">Conheça Gustavo Ramos, um jovem empreendedor e desenvolvedor com apenas 18 anos de idade. Como fundador da CodeMatch, ele tem liderado uma equipe dedicada a fornecer serviços de alta qualidade e segurança aos clientes. Com sua paixão por tecnologia e inovação, Gustavo está sempre buscando novas formas de atender às necessidades de seus clientes, oferecendo soluções personalizadas e eficazes. Sua habilidade em liderança e trabalho em equipe, juntamente com seu conhecimento técnico e visão empreendedora, têm sido fundamentais para o sucesso da CodeMatch e outras até o momento. Gustavo está ansioso para ajudar você a alcançar seus objetivos e superar suas expectativas. Conte com ele para entregar resultados excepcionais em todos os projetos.</Text>
						</Box>
					</Flex>
				</Container>
			</Box>							
		</Box>
	);
}